<template lang="pug">
div(v-if='Servicios' v-scrollanimation)
	//-Pc
	.d-none.d-md-block
		v-container(fluid).pa-0
			v-img(gradient="to top right, transparent , black" width='100%' height='600px' :src='Servicios.imagen' )
				v-row.mt-12
				v-row.mt-12
				v-row.mt-12
				v-row.mt-12
				v-row.mt-12
				v-row.mt-12
				v-row.mt-12
				v-row.mt-12
				v-row.mt-12
				v-row.mt-12
				v-row.mt-12.ml-12.mb-12
					div.rectangulo_titulo.secundario.mr-4.mt-4
					h1(v-html='Servicios.texto' style='font-size: 2em').white--text 
          
		.bloque-servicios.mt-12
			v-row.justify-center.align-center.centrado
				v-col(v-scrollanimation card='true'  cols='4' v-for='{boton,texto, imagen, titulo}, i in Servicios.servicios' :key='i')
					v-card(width='450' height='290' style='border-radius: 25px; margin-top: 3em').mx-auto.justify-center.align-items-center.rojo.elevation-0
						img(:src='imagen' width='180' style='position: absolute; top: -35%; height: 120px; left: 30%;')
						v-row.texto.justify-center.align-items-center
							v-container.pa-7.justify-center.align-items-center(style='text-align: center')
								h1( style='line-height : 25px; color:white' v-html='titulo').body-1.font-weight-black
								p( v-html='texto').body-2.font-weight-light.text-justify.white--text
	//-Móvil
	.d-md-none
		v-container(fluid).pa-0
			v-img(height='200px'  :src='Servicios.imagen' gradient="to top right, rgba(0, 0, 0,.8) ,rgba(69, 64, 64 ,.28)").d-none.d-sm-none
			v-img(height='auto'  :src='Servicios.imagen' gradient="to top right, rgba(0, 0, 0,.8) ,rgba(69, 64, 64 ,.28)").d-flex.d-sm-block

				.fila-texto-movil
					p(v-html='Servicios.textomovil' style='line-height : 24px').font-weight-regular.white--text.d-sm-none
					p(v-html='Servicios.textomovil' style='line-height : 3rem; font-size: 30px;').font-weight-regular.white--text.d-none.d-sm-block.d-md-none
					
				v-row.align-center.justify-center        
						v-col(md=11)
							v-row.mt-12 
							v-row.mt-12 
							v-row.mt-12 
							v-row.align-start.justify-start.mt-5.ml-4(v-scrollanimation) 
									div.rectangulo_titulo_movil.primario.mt-4.mr-2
									h2.white--text.mt-4 SERVICIOS
			v-row.justify-center.align-center(style='padding-bottom: 10%; padding-top: 20%')
				v-col(v-scrollanimation card='true' cols='10' sm='5'  v-for='{boton,texto, imagen, titulo}, i in Servicios.servicios' :key='i')
					v-card( style='border-radius: 30px; ' height='auto').rojo.card.mt-3
						v-img(:src='imagen' height='190px' width='100%' )
						v-card-text(style='padding: 5%')
							p( style='line-height : 35px; color:white;padding:1em' v-html='titulo').body-1.font-weight-black.text-center
							p( v-html='texto').body-2.font-weight-light.text-justify.white--text


		
</template>
<script>
  export default {
    data: () => ({
      show: false,
    }),
  }
</script>
<style lang="sass" scoped>
.card
	height: 380px
.fila-texto-movil
	position: absolute
	display: flex
	top: 3em
	left: 2em
	font-size: 14px
	img
		position: absolute
		display: flex
		width: 30px
		height: 30px
		top: 4rem
		left: -1em
.medidamovil
	padding-bottom: 10%

.fila-texto
	position: absolute
	display: flex
	top: 80%
	left: 10%
	img
		position: absolute
		display: flex
		width: 80px
		height: 70px
		top: 90%
		left: -14%
.bloque-servicios
	display: flex
	flex-direction: row
	width: 100%
	height: 950px
	.centrado
		padding-right: 0.01em
		
	
		.card
			width: 380px
			height: 360px
			border-radius: 25px
			margin-top: 3em
			.texto
				display: flex
				justify-content: center
				text-align: center!important
				position: absolute
				width: 300px
				top: 8em
				left: 20%
			img
				position: absolute
				top: -17%
				height: 150px
				left: 27%
		.bloque-naranja
			width: 170px
			height: 30px
			position: absolute
			top: 97%
			border-radius: 13px
			text-align: center
			padding-top: 2px
			span
				color: white
				
</style>

<style lang="sass" scoped>
	.rectangulo_titulo
		width: 14px
		height: 73px
	.rectangulo_titulo_movil
		width: 10px
		height: 40px
	.divider_inicial
		max-height: 10px!important
		height: 9px!important
	.before-enter
		opacity: 0
		transform: translatex(-100%)
		transition: all 1.5s ease-out 
		&[card='true']
			transform: scale(.5) rotateZ(-25deg)
			transition: all 1s ease-out  
	.enter
		opacity: 1
		transform: translateX(0px)
		&[card='true']
			transform: scale(1) rotateZ(0deg)
</style>